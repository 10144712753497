<template>
  <div class="va-row">
    <div class="flex md1"></div>
    <div class="flex md9">
      <vuestic-widget headerText="Esqueci minha senha">
        <p>Digite o endereço de e-mail usado para cadastrar
           a conta, para solicitar uma nova senha</p>
        <fieldset>
          <div class="form-group">
            <div class="input-group"> <template v-if="erro">
              <span class="alert-danger"
              v-for="e in erro.email"
:key="e" >
                {{e}} </span> </template>
              <input id="simple-input"
                type="email"
                required
                v-model="email.email">
              <label class="control-label" for="simple-input"></label>
              <i class="bar"></i>
            </div>
          </div>
        </fieldset>
        <div class="alinhar">
          <button class="btn btn-info btn-micro" @click="resetarSenha()">Solicitar</button>
          <button class="btn btn-dark btn-micro" @click="$router.push({name:'login'})">
            Cancelar</button>
        </div>
      </vuestic-widget>
    </div>

    <vuestic-modal
      :show.sync="show"
      ref="smallModal"
      v-on:ok = "$router.push({name:'login'})"
      v-bind:small="true"
      cancelClass="none"
      okText="OK"
    >
      <div slot="title">Solicitação Envidada</div>
      <div>Foi enviado um email para solicitação de uma nova senha para EMAIL DA PESSOA.</div>
    </vuestic-modal>
  </div>
</template>

<script>
export default {
  name: 'esqueci-minha-senha-email',

  data() {
    return {
      show: true,
      email: {
        email: '',
      },
      erro: null,
    };
  },
  methods: {
    resetarSenha() {
      this.$axios.post('/accounts/password_reset/', this.email).then(() => {
        this.$refs.smallModal.open();
        this.email.email = '';
        this.erro = null;
        // eslint-disable-next-line no-return-assign
      }).catch(error => this.erro = error.response.data);
    },
  },
};
</script>
